import React , { useState, useRef }  from 'react';
import Typed from 'react-typed';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {AnimatePresence, motion , useMotionValue, useTransform , useScroll} from "framer-motion/dist/framer-motion"; 

import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Logo1 from './Logo1';
import Fondo5 from './Fondo5';


function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY
  };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight
  };

  let reference = referenceElement.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY: (position.y - offset.top - offset.height / 2) / (offset.height / 2)
  };
}



const Start = () => {

    const { scrollYProgress } = useScroll();
//  const yValue = useTransform ( 
   //    scrollYProgress, 
   //    [0, 0.5, 1], 
   //    [0, 5000, 1000 * 4]
  //);


    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const [mousePosition, setMousePosition] = useState({});
    const boxRef = useRef();


    const rotateX = mousePosition.x;
    const rotateY = mousePosition.y;
    const rotateXd = mousePosition.width;
    const rotateXddl =  useTransform(x, [0, rotateXd], [-5, 5]);
    const rotateYddl =  useTransform(y, [0, rotateXd], [-5, 5]);

    const rotateXdd =  useTransform(x, [0, rotateXd], [90, -90]);
    const rotateYdd =  useTransform(y, [0, rotateXd], [-90, 90]);
    const rotateXddll =  useTransform(x, [0, rotateXd], [0, 0]);
    const rotateYddll =  useTransform(y, [0, rotateXd], [0,0]);
    const rotateXdds =  rotateXd / 2 ;

    const handleMouseMove = e => {
        setMousePosition(getRelativeCoordinates(e, boxRef.current));
        x.set(rotateX);
        y.set(rotateY);        
    };
           
    function handleMouseLeave(event) {
        x.set(rotateXdds);
        y.set(rotateXdds);
               
    }


        const titleini = {
          hidden: {
            opacity: 0,
          },
          visible: {
            opacity: 1,
            y: 50,
            transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
            delay:3.5  
            }
          }
        };


    return (
        <motion.section 
            onMouseMove={e => handleMouseMove(e)}  
            onMouseLeave={handleMouseLeave} 
            ref={boxRef}>
        <Fondo5 possx={rotateYddl} possy={rotateXddl} />
        <div className="container rn-start-area start" >
            <motion.div className="row" >
                <motion.div 
                    className="content-logo" 
                    style=""            
                    >
                    <motion.div className="col-lg-12 indicatorr"  
                        style={{
                            x: rotateXddl,
                            y: rotateYddl, 
                               
                        }}
                    >
                        <div className="contepup">
                            <Logo1 className="pup3" />
                        </div>
                    </motion.div>
                    <motion.div 
                        className="col-lg-12 mt_md--40 mt_sm--40 indicatorr"  
                        style={{
                            x: rotateXddl,
                            y: rotateYddl,
                            rotateX: rotateXddl,
                            rotateY: rotateYddl
                        }}>
                        <motion.div 
                            className="content"
                            variants={titleini}
                            initial="hidden"
                            animate="visible"
                        >
                            <div className="section-title">
                                <article className="conterota">               
                                    <img className="abajo1" src="./top.svg" />
                                    <h3 className="title text-center color-black">EL MUNDO KING ART <br /> {" "}
                                        <span className="subme">  
                                            <Typed className="theme-gradient"
                                              strings={[ 
                                              "React js Front.",                                                  
                                              "Jorge Vidal.",
                                              "Diseñador Ui/Ux.",
                                              "Front End",
                                              "Web Page Optimization.",
                                              "Marketing Digital.",
                                              "Seo.",                                    
                                              ]}
                                               typeSpeed={80}
                                               backSpeed={5}
                                                backDelay={1000}
                                               loop
                                            />
                                        </span>
                                    </h3>
                                    <img className="arriva1" src="./botton.svg" />
                                </article>
                            </div>
                            <div><Link activeClass="active" className="serviceslink bounce infinite" to="services" spy={true} smooth={true} duration={500} ><img className="botonini" src="./images/iconoinicio.svg" /></Link></div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    </motion.section>
    )
}
export default Start;